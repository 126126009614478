<template>
    <v-app id="inspire" style="background-color: transparent">
        <div class="container_evento_edit">
            <div class="mb-4">
                <h2>Cambiar imagen de preventa</h2>
            </div>
            <div class="col-12">
                <v-select
                    v-model="eventoEdit"
                    item-value="id"
                    item-text="nombre"
                    :items="listeventosPreventa"
                    no-data-text="Seleccione un evento"
                    label="Eventos"
                ></v-select>
            </div>
            <div class="col-12 mb-4" v-if="eventoEdit">
                <h3>Imagen Actual</h3>
                <img v-if="eventoSelect.url_imagen" :src="eventoSelect.url_imagen" alt="">
                <p v-else>El evento no tiene imagen de preventa</p>
            </div>
            <div class="col-12" v-if="eventoSelect">
                <h3 class="mb-3" v-if="haveNewImgPreventa">Nueva imagen de preventa</h3>
                <img src="" class="mb-4" id="imgPreventa"/>
                <div class="w-100 mb-3 text-center">
                    <input
                        type="file"
                        @change="getImageEventoMiniatura"
                        accept="image/*"
                        name="imgEventoMiniatura"
                        id="inputImgPreventa"
                        class="mx-auto"
                    />
                </div>
                <div class="text-center mt-4">
                    <button class="btn btn-primary" @click="ActualizarImagenEvento()">Actualizar imagen</button>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
export default {
    data() {
        return {
            eventoEdit: null,
            haveNewImgPreventa: false,
        }
    },
    mounted() {
        this.$store.dispatch('apariencia/getImgsPreventaEvento');
    },
    watch: {
        eventoEdit(){
            $("#imgPreventa").attr("src", '');
            this.haveNewImgPreventa = false;
        }
    },
    computed: {
        listeventosPreventa(){
            return this.$store.state.apariencia.eventosImgPreventa
        },
        eventoSelect(){
            if(this.eventoEdit){
                return this.listeventosPreventa.find((e)=>e.id == this.eventoEdit)
            }
            return null
        }
    },
    methods: {
        getImageEventoMiniatura(event) {
            if(event.srcElement && event.srcElement.files[0]){
                var reader = new FileReader();
                 reader.onload = function (e) {
                    $("#imgPreventa").attr("src", e.target.result);
                };
                reader.readAsDataURL(event.srcElement.files[0]);
                this.haveNewImgPreventa = true;
            }else{
                $("#imgPreventa").attr("src", '');
                this.haveNewImgPreventa = false;
            }
        },
        async ActualizarImagenEvento(){
            if(document.getElementById('inputImgPreventa').files[0]){
                var form = new FormData();
                form.append('imagen_preventa', document.getElementById('inputImgPreventa').files[0]);
                form.append('evento_id', this.eventoEdit);
                await this.$store.dispatch('apariencia/updateImgPreventa', form).then(()=>{
                    this.$store.dispatch('apariencia/getImgsPreventaEvento');
                    document.getElementById('inputImgPreventa').value = null;
                    $("#imgPreventa").attr("src", '');
                    this.haveNewImgPreventa = false;
                });
            }else{
                swal("Falta imagen", "Aun no se a seleccionado una imagen de preventa", "info");
            }            
        }
    },
}
</script>