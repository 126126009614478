<template>
    <v-app id="inspire">
        <div class="container-edit-home">
            <h2>Cambiar imagenes destacadas</h2>
            <p>Filtrar por país:</p>
            <div v-if="$store.state.apariencia.paisesHome">
                <v-select
                    class="selec-pais"
                    v-model="pais"
                    item-value="pais_id"
                    item-text="descripcion"
                    :items="$store.state.apariencia.paisesHome"
                    label="País"
                ></v-select>
            </div>
            <div class="container-change-banners" v-if="dataPais">
                <div class="col-12 mb-5 card">
                    <div class="d-flex flex-wrap mb-4">
                        <h3 class="w-100 text-center mb-4">Imagenes destacadas Izquierda</h3>
                        <div class="col-12">
                            <select v-model="eventoLeft" :key="eventoLeft" class="select-evento">
                                <option :value="null">Evento imagenes</option>
                                <option :value="option.id" :key="index" v-for="(option, index) in $store.state.apariencia.eventosImgPreventa">{{option.nombre}}</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-7">
                            <h3 class="mb-3 text-center" style="color: #898989">Imagen escritorio</h3>
                            <img class="mb-3" :src="dataPais.banners[0].banner_desktop" alt="">
                            <div v-if="haveNewImgLeft">
                                <h4>Nueva imagen destacada Escritorio</h4>
                                <img src="" class="mb-4" id="imgLeft"/>
                            </div>
                            <div class="w-100 mb-3 text-center">
                                <input
                                    type="file"
                                    @change="getImageEventoLeft"
                                    accept="image/*"
                                    name="imgLeft"
                                    id="inputImgLeft"
                                    class="mx-auto"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <h3 class="mb-3 text-center" style="color: #898989">Imagen movil</h3>
                            <img class="mb-3" :src="dataPais.banners[0].banner_movil" alt="">
                            <div v-if="haveNewImgMovilLeft">
                                <h4>Nueva imagen destacada Movil</h4>
                                <img src="" class="mb-4" id="imgMovilLeft"/>
                            </div>
                            <div class="w-100 mb-3 text-center">
                                <input
                                    type="file"
                                    @change="getImageEventoMiniaturaLeft"
                                    accept="image/*"
                                    name="imgMovilLefta"
                                    id="inputImgMovilLeft"
                                    class="mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-secundary" @click="actualizarImagenesLeft()">Actualizar banner izquierda</button>
                </div>
                <div class="col-12 card">
                    <div class="d-flex flex-wrap mb-4">
                        <h3 class="w-100 text-center mb-4">Imagenes destacadas Derecha</h3>
                        <div class="col-12">
                            <select v-model="eventoRight" :key="eventoRight" class="select-evento">
                                <option :value="null">Evento imagenes</option>
                                <option :value="option.id" :key="index" v-for="(option, index) in $store.state.apariencia.eventosImgPreventa">{{option.nombre}}</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-7">
                            <h3 class="mb-3 text-center" style="color: #898989">Imagen escritorio</h3>
                            <img class="mb-3" :src="dataPais.banners[1].banner_desktop" alt="">
                            <div v-if="haveNewImgRight">
                                <h4>Nueva imagen destacada Escritorio</h4>
                                <img src="" class="mb-4" id="imgRight"/>
                            </div>
                            <div class="w-100 mb-3 text-center">
                                <input
                                    type="file"
                                    @change="getImageEventoRight"
                                    accept="image/*"
                                    name="imgRight"
                                    id="inputImgRight"
                                    class="mx-auto"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-5">
                            <h3 class="mb-3 text-center" style="color: #898989">Imagen movil</h3>
                            <img class="mb-3" :src="dataPais.banners[1].banner_movil" alt="">
                            <div v-if="haveNewImgMovilRight">
                                <h4>Nueva imagen destacada Movil</h4>
                                <img src="" class="mb-4" id="imgMovilRight"/>
                            </div>
                            <div class="w-100 mb-3 text-center">
                                <input
                                    type="file"
                                    @change="getImageEventoMiniaturaRight"
                                    accept="image/*"
                                    name="imgMovilRight"
                                    id="inputImgMovilRight"
                                    class="mx-auto"
                                />
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-secundary" @click="actualizarImagenesRight()">Actualizar banner derecha</button>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
export default {
    data() {
        return {
            pais: 1,
            haveNewImgLeft: false,
            haveNewImgMovilLeft: false,
            haveNewImgRight: false,
            haveNewImgMovilRight: false,
            eventoLeft: null,
            eventoRight: null,
        }
    },
    watch: {
        pais(){
            $("#imgLeft").attr("src", '');
            this.haveNewImgLeft = false;
            $("#imgRight").attr("src", '');
            this.haveNewImgRight = false;

            $("#imgMovilLeft").attr("src", '');
            this.haveNewImgMovilLeft = false;
            $("#imgMovilRight").attr("src", '');
            this.haveNewImgMovilRight = false;

            this.eventoLeft = this.$store.state.apariencia.paisesHome.find(e => e.pais_id == this.pais).banners[0].evento_id.toString()
            this.eventoRight = this.$store.state.apariencia.paisesHome.find(e => e.pais_id == this.pais).banners[1].evento_id.toString()
        }
    },
    computed: {
        dataPais(){
            return this.$store.state.apariencia.paisesHome ? this.$store.state.apariencia.paisesHome.find(e => e.pais_id == this.pais) : null
        }
    },
    async created() {
        this.$store.state.apariencia.paisesHome = null;
        await this.$store.dispatch('apariencia/getImgsPreventaEvento');
        await this.$store.dispatch('apariencia/getBannersDestacados').then(async () => {
            this.eventoLeft = this.$store.state.apariencia.paisesHome.find(e => e.pais_id == this.pais).banners[0].evento_id.toString()
            this.eventoRight = this.$store.state.apariencia.paisesHome.find(e => e.pais_id == this.pais).banners[1].evento_id.toString()
        })
    },
    methods: {
        getImageEventoLeft(event) {
            if(event.srcElement && event.srcElement.files[0]){
                var reader = new FileReader();
                 reader.onload = function (e) {
                    $("#imgLeft").attr("src", e.target.result);
                };
                reader.readAsDataURL(event.srcElement.files[0]);
                this.haveNewImgLeft = true;
            }else{
                $("#imgLeft").attr("src", '');
                this.haveNewImgLeft = false;
            }
        },
        getImageEventoMiniaturaLeft(event) {
            if(event.srcElement && event.srcElement.files[0]){
                var reader = new FileReader();
                 reader.onload = function (e) {
                    $("#imgMovilLeft").attr("src", e.target.result);
                };
                reader.readAsDataURL(event.srcElement.files[0]);
                this.haveNewImgMovilLeft = true;
            }else{
                $("#imgMovilLeft").attr("src", '');
                this.haveNewImgMovilLeft = false;
            }
        },
        getImageEventoRight(event) {
            if(event.srcElement && event.srcElement.files[0]){
                var reader = new FileReader();
                 reader.onload = function (e) {
                    $("#imgRight").attr("src", e.target.result);
                };
                reader.readAsDataURL(event.srcElement.files[0]);
                this.haveNewImgRight = true;
            }else{
                $("#imgRight").attr("src", '');
                this.haveNewImgRight = false;
            }
        },
        getImageEventoMiniaturaRight(event) {
            if(event.srcElement && event.srcElement.files[0]){
                var reader = new FileReader();
                 reader.onload = function (e) {
                    $("#imgMovilRight").attr("src", e.target.result);
                };
                reader.readAsDataURL(event.srcElement.files[0]);
                this.haveNewImgMovilRight = true;
            }else{
                $("#imgMovilRight").attr("src", '');
                this.haveNewImgMovilRight = false;
            }
        },
        async actualizarImagenesLeft(){
            if(document.getElementById('inputImgMovilLeft').files[0] || document.getElementById('inputImgLeft').files[0]){
                var form = new FormData();
                form.append('banner_desktop', document.getElementById('inputImgLeft').files[0]);
                form.append('banner_movil', document.getElementById('inputImgMovilLeft').files[0]);
                form.append('evento_id', this.eventoLeft);
                form.append('pais_id', this.pais);
                form.append('orden', this.dataPais.banners[0].orden);
                await this.$store.dispatch('apariencia/updateImgDestacadas', form).then(()=>{
                    this.$store.dispatch('apariencia/getImgsPreventaEvento');
                    document.getElementById('inputImgPreventa').value = null;
                    $("#imgPreventa").attr("src", '');
                    this.haveNewImgPreventa = false;
                });
            }else{
                swal("Falta imagen", "Aun no se a seleccionado una imagen", "info");
            }            
        },
        async actualizarImagenesRight(){
            if(document.getElementById('inputImgMovilRight').files[0] || document.getElementById('inputImgRight').files[0]){
                var form = new FormData();
                form.append('banner_desktop', document.getElementById('inputImgRight').files[0]);
                form.append('banner_movil', document.getElementById('inputImgMovilRight').files[0]);
                form.append('evento_id', this.eventoRight);
                form.append('pais_id', this.pais);
                form.append('orden', this.dataPais.banners[1].orden);
                await this.$store.dispatch('apariencia/updateImgDestacadas', form).then(()=>{
                    this.$store.dispatch('apariencia/getImgsPreventaEvento');
                    document.getElementById('inputImgPreventa').value = null;
                    $("#imgPreventa").attr("src", '');
                    this.haveNewImgPreventa = false;
                });
            }else{
                swal("Falta imagen", "Aun no se a seleccionado una imagen", "info");
            }     
        }
    },
}
</script>
<style>
#inspire{
    background-color: transparent !important;
}
.container-edit-home{
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
}
.container-edit-home h2, .container-edit-home p {
    width: 100%;
}
.container-change-banners{
    display: flex;
    flex-wrap: wrap;
}
.select-evento{
    height: 30px;
    min-width: 200px;
    display: block;
    -webkit-appearance: auto !important;
    -moz-appearance: auto !important;
    border: 1px solid rgb(185, 185, 185);
    color: black !important;
    font-weight: bold;
    padding-left: .4rem;
    padding-right: .4rem;
    margin-bottom: 2rem;
}
</style>