<template>
    <div id="edit-apariencia">
        <div class="header-edit-apariencia">
            <h1>Editor de apariencia</h1>
            <p>En este apartado podras editar los elementos visuales de cada página, estos elementos visuales estan ligados a funcionalidades que cada componente o vista. No todas las páginas contienen elementos editables.</p>
        </div>
        <div class="body-edit-apariencia">
            <div class="card-pages">
                <h6 class="mb-3">Páginas: </h6>
                <div class="table-card-pages">
                    <div class="item-table-card-pages"
                        @click="layout = item.component"
                        :class="layout == item.component ? ('item-table-card-pages-selected ' + (index == 0 ? 'border-top-item' : (index == paginas.length - 1 ? 'border-bottom-item' : ''))) : ''"
                        :style="(index != (paginas.length -1)) ? 'border-bottom: 1px solid #d3d3d3;' : ''"
                        :key="index"
                        v-for="(item, index) in paginas">
                        <i class="fas d-flex align-items-center pr-3 fa-globe"></i>
                        <p>{{item.name}}</p>
                    </div>
                </div>
            </div>
            <div class="panel-edit-apariencia">
                <component :is="layout"></component>
            </div>
        </div>
    </div>
</template>
<script>
import HomeEdit from '@/components/Templates/Apariencia/HomeEdit.vue';
import EventoEdit from '@/components/Templates/Apariencia/EventoEdit.vue';
export default {
    components: {HomeEdit, EventoEdit},
    data() {
        return {
            layout: 'home-edit',
            paginas: [
                {
                    name: 'Home',
                    component: 'home-edit'
                },
                {
                    name: 'Evento',
                    component: 'evento-edit'
                }
            ]
        }
    },
    mounted() {
        document.title = 'Editar apariencia - TicketShop'
    },
}
</script>
<style>
#edit-apariencia{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.header-edit-apariencia{
    width: 90%;
    background-color: white;
    border-radius: 10px;
    padding: 1.5rem 2rem;
    box-shadow: 0 0 5px 0px rgb(219 219 219);
}
.body-edit-apariencia{
    width: 90%;
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;
}
.card-pages{
    width: 30%;
    background-color: white;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    box-shadow: 0 0 5px 0px rgb(219 219 219);
    margin-bottom: auto;
}
.panel-edit-apariencia{
    width: 68%;
    margin-left: auto;
    background-color: white;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    box-shadow: 0 0 5px 0px rgb(219 219 219);
}
@media (min-width: 1450px) {
.card-pages{
    width: 20%;
}
.panel-edit-apariencia{
    width: 78%;
}
}
.table-card-pages{
    width: 100%;
    border-radius: 10px;
    border: 1px solid #d3d3d3;
}
.item-table-card-pages{
    width: 100%;
    padding-left: 1rem;
    min-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.item-table-card-pages p{
    margin-bottom: 0;
}
.item-table-card-pages i{
    font-size: 1rem;
}
.item-table-card-pages-selected{
    background-color: rgb(226, 226, 226);
    border-bottom: 1px solid rgb(226, 226, 226);
    color: #5783c7;
    font-weight: bold;
}
.border-top-item{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.border-bottom-item{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
</style>